<template>
  <div class="card info-card mx-auto mx-md-0 my-0">
    <div class="d-flex flex-column p-2 pb-lg-5 text-center flex-fill position-relative">
      <div
        id="info-card-icon"
        class=" d-flex justify-content-center align-items-center position-absolute rounded-circle shadow-sm"
        :style="dynamicStyle"
      >
        <hs-icon :icon="icon" />
      </div>
      <span id="info-card-title" class="font-weight-bold mt-5 px-2 px-lg-3 px-xl-4">{{ title }}</span>
      <span id="info-card-subtitle" class="my-4 px-3 px-lg-4">{{ subtitle }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InformationCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    background: {
      type: String,
      default: '#ccf9fb',
    },
  },
  computed: {
    dynamicStyle() {
      return {
        background: this.background,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.info-card {
  width: 100%;
  max-width: 350px;
  height: 300px;

  #info-card-icon {
    left: calc(50% - 68px / 2 - 3px);
    top: -30px;
    width: 68px;
    height: 68px;
    font-size: 2rem;
    color: white;
  }

  #info-card-title {
    font-size: 20px;
    line-height: 115%;
  }
}

@media only screen and (min-width: $screen-sm) {
  .info-card {
    width: 85%;
  }
}
</style>
